import { getConfig, getTranslations } from "./data";
import { applyStyles } from "./htmlUtils";
import { CSS } from "./fancybannerStyles";
import { interpolate } from "../shared/modules/template";
import { BASE_BANNER_Z_INDEX } from "./storefront";

// TODO: CLEAN UP THIS FILE. was hastily put together in the interest of
// quick release, but this needs to be improved. it's all over the place.
export const injectCSS = () => {
  const target = document.body;
  const el = document.createElement("style");
  el.innerHTML = CSS;
  target.insertAdjacentElement("afterbegin", el);
  return el;
};

const INITIAL_MESSAGE = "Credit will be applied at checkout.";
const CANCEL_RETURN = "Cancel return";
const OTHER_OFFERS = "See other offers";
const REFUND_TITLE = "Refund";
const REFUND_SUBTITLE = "%{amount} full refund";
const CONFIRM_REFUND_TEXT =
  "Confirm that you would like to change your credit into a refund.";
const GIFT_CARD_TITLE = "Gift Card";
const GIFT_CARD_SUBTITLE = "%{amount} credit";
const CONFIRM_GIFT_CARD_TEXT =
  "Confirm that you would like to change your credit into a gift card.";
const CONFIRM_CANCEL_RETURN_TEXT =
  "Confirm that you would like to cancel your return.";
const CHECK_EMAIL_SUCCESS_TITLE = "A %{amount} refund is on its way!";
const CHECK_EMAIL_SUCCESS_TEXT =
  "Check your email for information on your refund.";
const CONFIRM_BUTTON_TEXT = "Confirm";
const CANCEL_RETURN_SUCCESS =
  "Your return has been canceled. Check your email for more information.";
const OR = " or ";

export const createFancyBanner = ({
  color,
  target = document.body,
  creditFormatted,
  originalPaymentRefundAmount,
  giftCardRefundAmount,
  onCancelReturn,
  onShopNowAbort,
}) => {
  const { branding } = getConfig() ?? {};
  const fixColor = (value, defaultValue) =>
    value ? `#${value}` : defaultValue;

  const bgColor = {
    primary: fixColor(branding?.brand_primary, "#000"),
    secondary: fixColor(branding?.brand_secondary, "#000"),
    error: fixColor(branding?.error_color, "#E63935"),
  };

  const textColor = {
    primary: "#fff",
    secondary: "#fff",
    error: fixColor(branding?.error_text_color, "#fff"),
  };

  const closeButtonStyles = `
    color: ${textColor.primary};
    cursor: pointer;
    line-height: 1.1rem;
  `;

  const refundAmountStyles = `
    border-color: ${textColor.primary};
  `;

  const optionStyles = `
    padding: 0.5rem 2.7rem;
    text-align: center;
    border-radius: 4rem;
    border-color: ${textColor.primary};
    border-width: 1px;
    background-color: transparent;
    font-size: 1.2rem;
    margin-right: 1rem;
    cursor: pointer;
  `;

  const arrowSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M19 12H5" stroke="${textColor.primary}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 19L5 12L12 5" stroke="${textColor.primary}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `;

  const closeSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_3861_15148)">
      <path d="M16 1.61074L9.61074 8L16 14.3893L14.3893 16L8 9.61074L1.61074 16L0 14.3893L6.38926 8L0 1.61074L1.61074 0L8 6.38926L14.3893 0L16 1.61074Z" fill="${textColor.primary}"/>
      </g>
      <defs>
      <clipPath id="clip0_3861_15148">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  `;

  function onOtherOptions() {
    window.narvarBanner.transition("showAllOptions");
  }

  function onClickBackToStart() {
    window.narvarBanner.transition("initialState");
  }

  function onClickRefund() {
    window.narvarBanner.transition("confirmRefund");
  }

  function onClickGiftCard() {
    window.narvarBanner.transition("confirmGiftCard");
  }

  function onDismissBanner() {
    window.narvarBanner.transition("dismiss");
  }

  async function onClickCancelReturn() {
    window.narvarBanner.transition("confirmCancelReturn");
  }

  async function onClickConfirmCancelReturn() {
    const errors = await onCancelReturn();

    if (errors && errors.length) {
      window.narvarBanner.transition("genericError");
      return;
    }
    window.narvarBanner.transition("successCancelReturn");
  }

  async function onConfirmRefund() {
    const { abortStatus, errors, totalRefundFormatted } = await onShopNowAbort(
      "original_payment",
    );

    if (abortStatus === "success") {
      window.narvarBanner.totalRefundFormatted = totalRefundFormatted;
      window.narvarBanner.transition("success");
    }
    if (abortStatus?.error || errors.length) {
      window.narvarBanner.transition("genericError");
    }
  }

  async function onConfirmGiftCard() {
    const { abortStatus, errors, totalRefundFormatted } = await onShopNowAbort(
      "gift_card",
    );

    if (abortStatus === "success") {
      window.narvarBanner.totalRefundFormatted = totalRefundFormatted;
      window.narvarBanner.transition("success");
    }
    if (abortStatus.error || errors.length) {
      window.narvarBanner.transition("genericError");
    }
  }

  const el = document.createElement("div");
  const translations = getTranslations();

  window.narvarBanner = {
    currentState: "initialState",
    totalRefundFormatted: "",
    transition: state => {
      window.narvarBanner.states[state].onEnter();
      window.narvarBanner.currentState = state;
    },
    states: {
      initialState: {
        onEnter: () => {
          // TODO: standardize on narvar_ or narvar__
          el.className = "narvar__banner";
          el.innerHTML = `
            <div class="narvar_banner_container" id="narvar_banner_container">
              <div class="narvar_step_container_center">
                <div class="refund_amount_container" style="${refundAmountStyles}">
                  ${creditFormatted}
                </div>
                <div class="text_left">
                  <div class="primary_text">${translations.shop_now_banner_title ||
                    INITIAL_MESSAGE}</div>
                  <div class="body_text">
                    <span class="text_link" id="narvar_cancel">${translations.shop_now_banner_cancel_label ||
                      CANCEL_RETURN}</span>${translations.shop_now_banner_or ||
            OR}<span class="text_link" id="narvar_other_options">${translations.shop_now_banner_other_offers_label ||
            OTHER_OFFERS}</span>
                  </div>
                </div>
              </div>
            </div>
          `;
          injectCSS();
          applyStyles(el, {
            width: "100%",
            padding: "2rem 1rem",
            textAlign: "center",
            backgroundColor: bgColor[color] || bgColor.primary,
            color: textColor[color] || textColor.primary,
            opacity: 1,
            position: "relative",
            zIndex: BASE_BANNER_Z_INDEX,
          });

          if (typeof target === "string") {
            try {
              target = document.querySelector(target);
            } catch (e) {
              console.error(e);
            }
          }
          if (target) {
            target.insertAdjacentElement("afterbegin", el);
          }

          // TODO: this is kind of hacky, because this code is being repeated in index.js,
          // but i think this was necessary to actually render the initial ui so we can add
          // click handlers in this state machine.
          document.body.classList.add("narvar_storefront_exchange_active");
          document
            .getElementById("narvar_cancel")
            .addEventListener("click", onClickCancelReturn);
          document
            .getElementById("narvar_other_options")
            .addEventListener("click", onOtherOptions);
        },
      },
      showAllOptions: {
        onEnter: () => {
          const bannerContainer = document.getElementById(
            "narvar_banner_container",
          );
          bannerContainer.innerHTML = `
            <div class="narvar_options_container">
              <span class="back_button" id="narvar_back_to_start">${arrowSvg}</span>
              <div class="narvar_options_content">
                <button id="narvar_option_refund" style="${optionStyles} color: ${
            textColor.primary
          };">
                  <div class="option_text">${translations.shop_now_banner_original_payment_title ||
                    REFUND_TITLE}</div>
                  <div class="option_price">${interpolate(
                    translations.shop_now_banner_original_payment_subtitle ||
                      REFUND_SUBTITLE,
                    { amount: originalPaymentRefundAmount },
                  )}</div>
                </button>
                <button id="narvar_option_gift_card" style="${optionStyles} color: ${
            textColor.primary
          };">
                  <div class="option_text">${translations.shop_now_banner_gift_card_title ||
                    GIFT_CARD_TITLE}</div>
                  <div class="option_price">${interpolate(
                    translations.shop_now_banner_gift_card_subtitle ||
                      GIFT_CARD_SUBTITLE,
                    { amount: giftCardRefundAmount },
                  )}</div>
                </button>
              </div>
            </div>
          `;

          injectCSS();
          document
            .getElementById("narvar_back_to_start")
            .addEventListener("click", onClickBackToStart);
          document
            .getElementById("narvar_option_refund")
            .addEventListener("click", onClickRefund);
          document
            .getElementById("narvar_option_gift_card")
            .addEventListener("click", onClickGiftCard);
        },
      },
      confirmRefund: {
        onEnter: () => {
          const bannerContainer = document.getElementById(
            "narvar_banner_container",
          );
          bannerContainer.innerHTML = `
            <div class="narvar_step_container_space">
              <span class="back_button" id="narvar_back_to_options">${arrowSvg}</span>
              <div class="body_text confirm_text">${translations.shop_now_banner_original_payment_confirm ||
                CONFIRM_REFUND_TEXT}</div>
              <button id="narvar_confirm_button" class="confirm_button">${translations.shop_now_banner_confirm ||
                CONFIRM_BUTTON_TEXT}</button>
            </div>
          `;
          document
            .getElementById("narvar_confirm_button")
            .addEventListener("click", onConfirmRefund);
          document
            .getElementById("narvar_back_to_options")
            .addEventListener("click", onOtherOptions);
        },
      },
      confirmGiftCard: {
        onEnter: () => {
          const bannerContainer = document.getElementById(
            "narvar_banner_container",
          );
          bannerContainer.innerHTML = `
            <div class="narvar_step_container_space">
              <span class="back_button" id="narvar_back_to_options">${arrowSvg}</span>
              <div class="body_text confirm_text">${translations.shop_now_banner_gift_card_confirm ||
                CONFIRM_GIFT_CARD_TEXT}</div>
              <button id="narvar_confirm_button" class="confirm_button">${translations.shop_now_banner_confirm ||
                CONFIRM_BUTTON_TEXT}</button>
            </div>
          `;
          document
            .getElementById("narvar_confirm_button")
            .addEventListener("click", onConfirmGiftCard);
          document
            .getElementById("narvar_back_to_options")
            .addEventListener("click", onOtherOptions);
        },
      },
      confirmCancelReturn: {
        onEnter: () => {
          const bannerContainer = document.getElementById(
            "narvar_banner_container",
          );
          bannerContainer.innerHTML = `
            <div class="narvar_step_container_space">
              <span class="back_button" id="narvar_back_to_options">${arrowSvg}</span>
              <div class="body_text confirm_text">${translations.shop_now_banner_cancel_confirm ||
                CONFIRM_CANCEL_RETURN_TEXT}</div>
              <button id="narvar_confirm_button" class="confirm_button">${translations.shop_now_banner_confirm ||
                CONFIRM_BUTTON_TEXT}</button>
            </div>
          `;
          document
            .getElementById("narvar_confirm_button")
            .addEventListener("click", onClickConfirmCancelReturn);
          document
            .getElementById("narvar_back_to_options")
            .addEventListener("click", onClickBackToStart);
        },
      },
      success: {
        onEnter: () => {
          const bannerContainer = document.getElementById(
            "narvar_banner_container",
          );
          bannerContainer.innerHTML = `
            <div class="narvar_step_container_space">
              <div class="text_spacer">
                <div class="primary_text">${interpolate(
                  translations.shop_now_banner_refund_success_title ||
                    CHECK_EMAIL_SUCCESS_TITLE,
                  { amount: window.narvarBanner.totalRefundFormatted },
                )}</div>
                <div class="body_text">${translations.shop_now_banner_refund_success ||
                  CHECK_EMAIL_SUCCESS_TEXT}</div>
              </div>
              <span style="${closeButtonStyles}" id="narvar_banner_close">${closeSvg}</span>
            </div>
          `;

          document
            .getElementById("narvar_banner_close")
            .addEventListener("click", onDismissBanner);
        },
      },
      successCancelReturn: {
        onEnter: () => {
          const bannerContainer = document.getElementById(
            "narvar_banner_container",
          );
          bannerContainer.innerHTML = `
            <div class="narvar_step_container_space">
              <div class="text_spacer">
                <div class="body_text">${translations.shop_now_banner_cancel_success ||
                  CANCEL_RETURN_SUCCESS}</div>
              </div>
              <span style="${closeButtonStyles}" id="narvar_banner_close">${closeSvg}</span>
            </div>
          `;

          document
            .getElementById("narvar_banner_close")
            .addEventListener("click", onDismissBanner);
        },
      },
      genericError: {
        onEnter: () => {
          const bannerContainer = document.getElementById(
            "narvar_banner_container",
          );
          bannerContainer.innerHTML = `
            <div class="narvar_step_container_space">
              <div class="body_text text_spacer">We're sorry, something went wrong. Please try again later.</div>
              <span style="${closeButtonStyles}" id="narvar_banner_close">${closeSvg}</span>
            </div>
          `;

          document
            .getElementById("narvar_banner_close")
            .addEventListener("click", onDismissBanner);
        },
      },
      dismiss: {
        onEnter: () => {
          applyStyles(el, {
            position: "fixed",
            zIndex: BASE_BANNER_Z_INDEX,
            transition: "opacity 0.8s",
          });
          setTimeout(() => {
            el.style.opacity = 0;
            setTimeout(() => el.remove(), 1000);
          }, 500);
        },
      },
    },
  };
  window.narvarBanner.states["initialState"].onEnter();
};
