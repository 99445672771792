import Rollbar from "rollbar";
import get from "lodash/get";

let rollbarInstance = {};

const nvoConfig = get(window, "nvo_config");

const init = (token, env, optionsOverride = {}) => {
  const accessToken = token || get(window, "nvo_config.rollbarKey");
  const environment = env || get(window, "nvo_config.environment");

  rollbarInstance = new Rollbar({
    accessToken,
    captureUncaught: true,
    captureUnhandledRejections: false,
    includeItemsInTelemetry: true,
    maxTelemetryEvents: 100, // maximum rollbar events queue size, payload should < 100kb in normal situation
    verbose: true,
    autoInstrument: {
      networkRequestBody: true, // log request body, better visibility for graphql request
    },

    // > Source maps are automatically enabled in your project, but you still
    // > need to add the code_version parameter to the _rollbarConfig object:
    // https://docs.rollbar.com/docs/source-maps#2-configure-the-rollbarjs-sdk-to-support-source-maps
    //
    // I think we don't ever have to change this `codeVersion`, because we have
    // configured Sprockets to add a digest to our file names.
    codeVersion: "1",

    scrubFields: [
      "rollbarKey",
      "rollbarKeyForShopNow",
      "google_fonts_api_key",
      "apiKey",
      "shop_stripe_acc",
      "country_codes",
    ],
    enabled: true,
    environment,
    hostSafeList: ["shopifyzero.narvar.com", "szero.narvar.com"],
    ignoredMessages: [
      "Order not found",
      "Could not find any order.",
      "Could not retrieve order details.",
      "Email address does not match order number",
      "(unknown): Script error.",
      "Stores not found within", // Our consumer app returns this when the instore return location picker does not find any stores.
      "No locations found near ZIP code",
      /GraphQL\(GetReturnLocations\):/, // no return locations found and no error message translation in consumer app
      "Unable to return all items in the same method. Please try again by returning one item at a time.",
      "There was an issue generating your return label. Please verify your address and try again.",
      "One or more items are not eligible for return",
      "All items have been returned.",
      "There was an issue generating your label",
      "One or more items are not eligible for return",
      "There was an error submitting the return. Please try again.",
      "Address Not Found",
      "Unexpected token < in JSON at position 0 at new t", // The order lookup graphql call results in this from apollo
      "JSON Parse error", // The order lookup graphql call results in this from apollo
      "JSON\\.parse", // This happens on 500
      "Invalid Request For Shop Context", // Returned when the requested ID of an object does not match the shop context
      "Unexpected token < in JSON", // This happens on 500
      "Received status code 500",
      "Network error: undefined",
      "PG::QueryCanceled: ERROR:  canceling statement due to statement timeout",
      "Carrier service cannot be removed because it is used by a Return Method Rule",
      "Could not find tracking information",
      "AbortError: Request signal is aborted",
      "Request signal is aborted",
      "What is your address?", // in-store order(POS order) won't have an address, expected error
      "ResizeObserver loop", // https://app.rollbar.com/a/narvar/fix/item/ShopifyZero/3191
    ],
    filterTelemetry(event) {
      // ignore all console.debug() log and do not store in rollbar
      if (event.type === "log" && event.level === "debug") {
        return true;
      }

      // only keep data and geocoding XHR, ignore others map styles and images fetching
      if (
        event.type === "network" &&
        (event.body.subtype === "xhr" || event.body.subtype === "fetch") &&
        /^https:\/\/api\.maptiler\.com\/(?!(data\/|geocoding\/))/.test(
          event.body.url,
        )
      ) {
        return true;
      }

      return false;
    },
    ...optionsOverride,
  });

  return rollbarInstance;
};
const configure = cfg => {
  if (rollbarInstance) {
    rollbarInstance.configure(cfg);
  }
};

const setUser = user => configure({ payload: { person: user } });

// update and merge feature flags (A/B testing) as global rollbar debug payload
const setFeatures = feature_flags => configure({ payload: { feature_flags } });

const createLogger = level => (msg, meta) => {
  console.log(`reporting ${level} message:`, msg, get(msg, "message"));
  rollbarInstance[level](get(msg, "message") || msg, { nvoConfig, meta });
};

const debug = createLogger("debug");
const info = createLogger("info");
const warn = createLogger("warn"); // handled error can log as warning
const error = createLogger("error"); // all apollo error should log as error
const critical = createLogger("critical"); // all uncaught error should log as critical, and have meta { caughtByErrorBoundary: true }

export default {
  init,
  configure,
  setUser,
  setFeatures,
  debug,
  info,
  warn,
  error,
  critical,
};
