/**
 * @module
 * @private
 */

import { createStorefrontApiClient } from "@shopify/storefront-api-client";
import { gql } from "@apollo/client";
import { createCache, createClient } from "../shared/modules/apollo";
import { compose, fromPairs, get, map } from "lodash/fp";
import { isLocalStorageEnabled } from "../shared/modules/localStorage";
import { renameKeys } from "../shared/modules/object";
import { CHECKOUT_CACHE_KEY, CONFIG_CACHE_KEY, APP_DOMAIN } from "./const";
import * as errorNotifier from "./errorNotifier";
import { createNarvarClient } from "./modules/apollo";

/**
 * @typedef color
 * @type {string}
 * @example
 * const white = "FFF";
 * const black = "000000";
 */
/**
 * @typedef font
 * @type {string}
 */
/**
 * @typedef url
 * @type {string}
 */
/**
 * @typedef ShopNowBranding
 * @type {object}
 * @property {number} base_body_text_size: 14
 * @property {color} body_background: "EEEEEE"
 * @property {color} body_text: "333333"
 * @property {number} border_radius: 10
 * @property {color} brand_primary: "F6B73C"
 * @property {color} brand_secondary: "65BBe6"
 * @property {color} error_color: "E63935"
 * @property {color} error_text_color: "FFFFFF"
 * @property {font} font_name: null
 * @property {url} font_url: null
 * @property {boolean} is_link_all_caps: false
 * @property {boolean} is_link_underlined: true
 * @property {font} primary_font: "Roboto"
 * @property {font} secondary_font: "Roboto Slab"
 */

/**
 * @typedef ShopNowLineItem
 * @type {object}
 * @property {string} id variant ID
 * @property {number} quantity
 */
/**
 * @typedef ShopNowSession
 * @type {object}
 * @property {string} shopId
 * @property {string} returnId
 * @property {string} code
 * @property {string} currency
 * @property {number} credit
 * @property {string} creditFormatted
 * @property {string} originalPaymentRefundAmount
 * @property {string} giftCardRefundAmount
 * @property {string[]} exchangeItemIds
 * @property {ShopNowLineItem[]} exchangeItems
 * @property {number} expiry
 * @property {boolean} enableOrderSummary
 * @property {string} orderSummaryText
 */

/**
 * clear the shopnow session and remove the banner
 * @returns {ShopNowSession} the original cached shopnow session
 */
export function clearSession() {
  if (!isLocalStorageEnabled) return null;

  const cache = getCache(false);
  window.localStorage.removeItem(CHECKOUT_CACHE_KEY);
  const el = document.querySelector(".narvar__banner");
  if (el) el.remove();
  return cache;
}

export function getJSONLocalStorage(key) {
  if (!isLocalStorageEnabled) return null;

  let cache = null;
  try {
    const json = window.localStorage.getItem(key);
    if (json) {
      cache = JSON.parse(json);
    }
  } catch (err) {
    console.error("getJSONLocalStorage JSON parse error");
    errorNotifier.error(err);
  }
  return cache;
}

export function setJSONLocalStorage(key, value) {
  if (!isLocalStorageEnabled) return;

  window.localStorage.setItem(key, JSON.stringify(value));
}

/**
 * get cached shopnow session in local storage, when enforceTTL is enabled, it
 * would clear session and remove banner when session is expired.
 * @param {boolean} [enforceTTL=true] flag to enforce TTL, default to true
 * @returns {ShopNowSession} cached shopnow session
 */
export function getCache(enforceTTL = true) {
  // get cached shopnow record from local storage
  let cache = getJSONLocalStorage(CHECKOUT_CACHE_KEY);

  // Enforce TTL
  if (enforceTTL && cache) {
    const now = new Date();
    if (now.getTime() > cache.expiry) {
      console.warn("shop now session expired");
      clearSession();
      cache = null;
    }
  }

  return cache;
}

/**
 *
 * @param {object} updates data to update on shopnow session
 * @returns {ShopNowSession} cached shopnow session
 */
export function setCache(updates) {
  let cache = getCache(false) || {};
  cache = { ...cache, ...updates };
  setJSONLocalStorage(CHECKOUT_CACHE_KEY, cache);
  return cache;
}

export function getConfig() {
  return getJSONLocalStorage(CONFIG_CACHE_KEY);
}

export function setConfig(updates) {
  let config = getConfig() || {};
  config = { ...config, ...updates };
  return setJSONLocalStorage(CONFIG_CACHE_KEY, config);
}

export const hasLocalSession = () => !!getCache(true);

export const getTranslations = () => getConfig()?.translations || {};

/**
 * @typedef ShopNowCheckoutResult
 * @type {object}
 * @property {string} weburl shopify checkout url
 * @property {string} status shopify checkout status, "ok" or "invalid"
 */
/**
 * submit shopnow checkout to narvar
 * @param {object} cart
 * @param {string} cart.shid
 * @param {string} cart.code
 * @param {string} cart.currency
 * @param {ShopNowLineItem[]} cart.items
 * @returns {Promise<ShopNowCheckoutResult>}
 */
export const submitCheckout = ({ items, code }) =>
  fetch(`${APP_DOMAIN}/api/shop_now_checkout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify({ items, code }),
  })
    .then(res => res.json())
    .then(res => {
      // fatal error case is handle by its caller `onSubmit()`
      if (res.status === "invalid" || !res.weburl) {
        throw new Error(res?.message || JSON.stringify(res));
      }

      // TODO: any other checking?
      return res;
    });

export const getShopBranding = shid =>
  fetch(
    `${APP_DOMAIN}/api/shop_now_branding?shid=${encodeURIComponent(shid)}`,
    {
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
    .then(res => res.json())
    .then(res => {
      if (res.status === "invalid" || !res.data) {
        throw new Error(res?.message || JSON.stringify(res));
      }
      return res.data;
    });

const CART_QUERY = `
  query CartQuery($id: ID!) {
    cart(id: $id) {
      id
      note
      attributes {
        key
        value
      }
      lines(first:100) {
        edges {
          node {
            id
            quantity
            attributes {
              key
              value
            }
            merchandise {
              ... on ProductVariant {
                id
                title
                sku
                product {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SHOP_NOW_ABORT = gql`
  mutation ShopNowAbort($returnId: String!, $refundMethod: String!) {
    shopNowAbort(returnId: $returnId, refundMethod: $refundMethod) {
      abortStatus
      errors {
        message
        code
      }
      totalRefundFormatted
    }
  }
`;

const CANCEL_RETURN = gql`
  mutation cancelReturn(
    $orderNumber: String
    $email: String
    $shopId: String
    $returnId: String
  ) {
    cancelReturn(
      orderNumber: $orderNumber
      email: $email
      shopId: $shopId
      returnId: $returnId
    )
  }
`;

const SHOP_NOW_DATA = gql`
  query ShopNowSession(
    $code: String!
    $categories: [String!]!
    $locale: String
  ) {
    shopNowSession(code: $code) {
      expired
      expiresAt
      returnStatus
      shopNowCredit {
        currency
        cents
        dollars
        formattedAmount
      }
      abortOptions {
        type
        refundAmount {
          formattedAmount
        }
      }
      shopNowAbortEnabled
      shopNowCheckoutOrderSummaryEnabled
    }
    translations(categories: $categories, locale: $locale) {
      key
      value
    }
  }
`;

export const getCart = async (cartGid, cfg) => {
  if (!cartGid || !cfg?.storefrontAccessToken) {
    // Use the simplest ajax cart api to get the items in the cart.
    // API Doc: https://shopify.dev/docs/api/ajax/reference/cart
    return fetch("/cart.js").then(res => res.json());
  }

  // SHOPZ-2559: Use storefront api to get the items in the cart.
  // API Doc: https://shopify.dev/docs/api/storefront
  const client = createStorefrontApiClient({
    storeDomain: window.location.origin,
    apiVersion: "2024-01",
    publicAccessToken: cfg.storefrontAccessToken,
    // R-29433: if we didn't specify the fetch function, the underlying
    // storefront API would use a wrong fetch function in the production bundle,
    // which did nothing and caused an error. The root cause is not clear.
    customFetchApi: window.fetch,
  });
  const { data, errors, extensions } = await client.request(CART_QUERY, {
    variables: {
      id: cartGid,
    },
  });

  if (errors) {
    throw errors;
  }

  const formatAttributes = compose(
    fromPairs,
    map(attribute => [attribute.key, attribute.value]),
  );

  const formatItem = compose(
    ({ attributes, merchandise, ...item }) => ({
      ...item,
      properties: formatAttributes(attributes),
      product_id: merchandise.product.id,
      ...renameKeys({ id: "variant_id" }, merchandise),
    }),
    get("node"),
  );

  const result = {
    ...data.cart,
    attributes: formatAttributes(data.cart.attributes),
    items: data.cart.lines.edges.map(formatItem),
  };

  return result;
};

export const clearCart = () =>
  fetch("/cart/clear.js", {
    method: "POST",
    mode: "cors",
  })
    .then(res => res.json())
    .then(res => {
      console.debug("shopnow shopping cart cleared", res);
    })
    .catch(err => {
      errorNotifier.error(err);
    });

export async function getShopNowData({ code, locale, shopId }) {
  const client = createNarvarClient(shopId);
  try {
    const { data, errors } = await client.mutate({
      mutation: SHOP_NOW_DATA,
      variables: {
        code,
        categories: ["shop_now"],
        locale: locale || window.Shopify?.locale || null,
      },
    });

    return { data, errors };
  } catch (err) {
    return { errors: [{ error: err }] };
  }
}

export async function shopNowAbort({ returnId, refundMethod, shopId }) {
  const client = createNarvarClient(shopId);
  try {
    const { data, errors } = await client.mutate({
      mutation: SHOP_NOW_ABORT,
      variables: {
        returnId,
        refundMethod,
      },
    });

    return data?.shopNowAbort;
  } catch (err) {
    return { errors: [{ error: err }] };
  }
}

export async function shopNowCancelReturn({ shopId, returnId }) {
  const client = createNarvarClient(shopId);
  try {
    const { data, errors } = await client.mutate({
      mutation: CANCEL_RETURN,
      variables: {
        returnId,
      },
    });

    return { data, errors };
  } catch (err) {
    return { errors: [{ error: err }] };
  }
}
