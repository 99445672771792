import EventEmitter from "eventemitter3";

export const ERROR_EVENTS = {
  generalError: "GENERAL_ERROR",
  sessionInvalid: "SESSION_INVALID",
  sessionStale: "SESSION_STALE",
  shopUserUnregistered: "SHOP_USER_UNREGISTERED",
  shopUserUnverified: "SHOP_USER_UNVERIFIED",
  shopUserUnauthorized: "SHOP_USER_UNAUTHORIZED",
  shopUserDisabled: "SHOP_USER_DISABLED",
};

const emitter = new EventEmitter();

export const emitSessionStale = error => {
  emitter.emit(ERROR_EVENTS.sessionStale, error);
};

export const emitSessionInvalid = error => {
  emitter.emit(ERROR_EVENTS.sessionInvalid, error);
};

export const emitGeneralError = error => {
  emitter.emit(ERROR_EVENTS.generalError, error);
};

export const emitShopUserUnauthorized = error => {
  emitter.emit(ERROR_EVENTS.shopUserUnauthorized, error);
};

export const onSessionStale = fn => {
  emitter.on(ERROR_EVENTS.sessionStale, fn);
  return () => emitter.removeListener(ERROR_EVENTS.sessionStale, fn);
};

export const onSessionInvalid = fn => {
  emitter.on(ERROR_EVENTS.sessionInvalid, fn);
  return () => emitter.removeListener(ERROR_EVENTS.sessionInvalid, fn);
};

export const onGeneralError = fn => {
  emitter.on(ERROR_EVENTS.generalError, fn);
  return () => emitter.removeListener(ERROR_EVENTS.generalError, fn);
};

export const onShopUserUnauthorized = fn => {
  emitter.on(ERROR_EVENTS.shopUserUnauthorized, fn);
  return () => emitter.removeListener(ERROR_EVENTS.shopUserUnauthorized, fn);
};
